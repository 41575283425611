<template>
    <div class="auth-modal">
        <div @click="openModal" data-default-auth-handler="" class="hidden-field hidden-auth-handler"></div>
        <public-modal-view
            :without-close="!!visible"
            :flat="flat"
            :step-key="currentFlow.key"
            :loading="loading"
            @close="hideModal"
            v-show="isModalVisible"
        >
            <AuthorizationStaticFlow
                v-if="currentFlow.key === 'STATIC_AUTHORIZATION'"
                @set="currentFlow = $event"
                @loading="loading = $event"
                :key="currentFlow.key"
                :previous="currentFlow.previous"
                :data="currentFlow.data"
                :additional-fields="additionalFields"
            />
            <AuthorizationByCodeFlow
                v-if="currentFlow.key === 'AUTHORIZATION_BY_CODE'"
                @set="currentFlow = $event"
                @loading="loading = $event"
                :key="currentFlow.key"
                :previous="currentFlow.previous"
                :data="currentFlow.data"
                :additional-fields="additionalFields"
            />
            <AuthorizationByPasswordFlow
                v-if="currentFlow.key === 'AUTHORIZATION_BY_PASSWORD'"
                @set="currentFlow = $event"
                @loading="loading = $event"
                :key="currentFlow.key"
                :previous="currentFlow.previous"
                :data="currentFlow.data"
                :additional-fields="additionalFields"
            />
            <AuthorizationRestoreFlow
                v-if="currentFlow.key === 'RESTORE_ACCOUNT'"
                @set="currentFlow = $event"
                @loading="loading = $event"
                :key="currentFlow.key"
                :previous="currentFlow.previous"
                :data="currentFlow.data"
                :additional-fields="additionalFields"
            />
            <AuthorizationDynamicFlow
                v-if="currentFlow.key === 'DYNAMIC_FLOW'"
                @set="currentFlow = $event"
                @loading="loading = $event"
                :key="currentFlow.key"
                :previous="currentFlow.previous"
                :data="currentFlow.data"
                :additional-fields="additionalFields"
            />
            <InformAccountRestored
                v-if="currentFlow.key === 'ACCOUNT_RESTORED'"
                @set="currentFlow = $event"
                @loading="loading = $event"
                :key="currentFlow.key"
                :previous="currentFlow.previous"
                :data="currentFlow.data"
                :additional-fields="additionalFields"
            />
            <InformProductPrerecording
                v-if="currentFlow.key === 'PRODUCT_PRERECORDING'"
                @set="currentFlow = $event"
                @loading="loading = $event"
                :key="currentFlow.key"
                :previous="currentFlow.previous"
                :data="currentFlow.data"
            />
        </public-modal-view>
    </div>
</template>

<script>
import PublicModalView from "../components/PublicModalView"
import PublicModalMixin from "../shared/mixins/public-modal-mixin"
import { DEFAULT_AUTH_KEY } from "../shared/keys/default-auth-key"

import { EventBus } from "~events"
import { FLOW_TYPES } from "../components/flow/data/flow-types"
import { FLOW_VIEWS } from "../components/flow/data/flow-views"

import AuthRepository from "../shared/repository/auth-repository"
import AuthorizationStaticFlow from "../components/flow/AuthorizationStaticFlow"
import AuthorizationByCodeFlow from "../components/flow/AuthorizationByCodeFlow"
import AuthorizationByPasswordFlow from "../components/flow/AuthorizationByPasswordFlow"
import AuthorizationRestoreFlow from "../components/flow/AuthorizationRestoreFlow"
import AuthorizationDynamicFlow from "../components/flow/AuthorizationDynamicFlow"
import InformAccountRestored from "../components/inform/InformAccountRestored"
import InformProductPrerecording from "../components/inform/InformProductPrerecording"

/**
 * @description The components of the modal window are dynamically substituted depending on the passed key
 * @see matching type-view {@link FLOW_TYPES} and {@link FLOW_VIEWS}
 */

export default {
    props: {
        config: {
            type: Object,
            default: () => ({
                key: "",
                data: {}
            })
        },
        loginAction: {
            type: Function,
            default: null,
            required: false
        },
        additionalFields: {
            type: Object,
            default: () => null
        }
    },
    name: "DefaultAuthModal",
    mixins: [PublicModalMixin],
    components: {
        InformProductPrerecording,
        InformAccountRestored,
        AuthorizationDynamicFlow,
        AuthorizationByPasswordFlow,
        AuthorizationRestoreFlow,
        AuthorizationByCodeFlow,
        AuthorizationStaticFlow,
        PublicModalView
    },
    data() {
        return {
            currentFlow: null,
            subscriptions: null,
            loading: false
        }
    },
    created() {
        if (!this.config.key) {
            this.currentFlow = FLOW_TYPES.STATIC_AUTHORIZATION
        } else {
            this.currentFlow = this.config
        }

        if (this.visible) {
            this.isModalVisible = true
        }

        this.subscriptions = [
            EventBus.$on(`${DEFAULT_AUTH_KEY}_OPEN`, handler => {
                this.openModal()
                if (handler && handler.hasAttribute("data-tariff-id")) {
                    AuthRepository.TariffId = handler.getAttribute("data-tariff-id")
                }
                if (handler && handler.hasAttribute("data-tariff-number")) {
                    AuthRepository.multiplier = handler.getAttribute("data-tariff-number")
                }
                if (handler && handler.hasAttribute("data-webinar-tariff-id")) {
                    AuthRepository.WebinarTariffId = handler.getAttribute("data-webinar-tariff-id")
                }
                if (handler && handler.hasAttribute("data-prerecording") && handler.hasAttribute("data-product-id")) {
                    AuthRepository.loginAction = this.prerecordingLoginActionAfter.bind(
                        this,
                        handler.getAttribute("data-product-id")
                    )
                    this.subscriptions.push(() => {
                        AuthRepository.loginAction = null
                    })
                }
            }),
            EventBus.$on(`${DEFAULT_AUTH_KEY}_HIDE`, () => {
                this.hideModal()
            })
        ]

        if (this.loginAction) {
            AuthRepository.loginAction = this.loginAction
            this.subscriptions.push(() => {
                AuthRepository.loginAction = null
            })
        }
    },
    methods: {
        prerecordingLoginActionAfter(...args) {
            this.currentFlow = {
                ...FLOW_TYPES.PRODUCT_PRERECORDING,
                data: {
                    productId: args[0] || null
                }
            }
        },
        getFlowView() {
            return FLOW_VIEWS[this.currentFlow.key]
        }
    },
    destroyed() {
        if (this.subscriptions) {
            for (const unsubscribe of this.subscriptions) {
                unsubscribe()
            }
        }
    }
}
</script>
